var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "passwordInputRef", staticClass: "bs-password-input" },
    [
      _c("input", {
        ref: "realInput",
        staticClass: "hidden-input",
        attrs: { type: "number", inputmode: "numeric" },
        on: { input: _vm.onInput, blur: _vm.blur },
      }),
      _c(
        "ul",
        {
          staticClass: "bs-password-input-security",
          class: {
            "has-gap": _vm.hasGap,
          },
          on: { click: _vm.focus },
        },
        _vm._l(_vm.passwords, function (pwd, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "bs-password-input-item",
              class: {
                "is-focus": _vm.focusInputIndex === index,
              },
            },
            [
              _vm.mask && pwd !== " "
                ? _c("i", { staticClass: "password-input-dot" })
                : _vm._e(),
              !_vm.mask ? [_vm._v(_vm._s(pwd))] : _vm._e(),
              _vm.showInputCursor
                ? _c("div", { staticClass: "bs-password-input-cursor" })
                : _vm._e(),
            ],
            2
          )
        }),
        0
      ),
      _vm.info
        ? _c("div", { staticClass: "bs-password-input-info" }, [
            _vm._v(_vm._s(_vm.info)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }