<template>
  <div class="bs-password-input" ref="passwordInputRef">
    <input
      ref="realInput"
      type="number"
      class="hidden-input"
      inputmode="numeric"
      @input="onInput"
      @blur="blur">
    <ul
      class="bs-password-input-security"
      :class="{
        'has-gap': hasGap
      }"
      @click="focus">
      <li
        class="bs-password-input-item"
        :class="{
          'is-focus': focusInputIndex === index
        }"
        v-for="(pwd, index) in passwords"
        :key="index">
        <i v-if="mask && (pwd !== ' ')" class="password-input-dot"></i>
        <template v-if="!mask">{{ pwd }}</template>
        <div
          v-if="showInputCursor"
          class="bs-password-input-cursor"></div>
      </li>
    </ul>
    <div class="bs-password-input-info" v-if="info">{{ info }}</div>
  </div>
</template>
<script>
export default {
  name: "PasswordInput",
  props: {
    pwdd: { // 密码值
      type: [String, Number],
      default: ''
    },
    hasGap: { // 是否有间隙
      type: Boolean,
      default: false
    },
    mask: { // 是否隐藏密码内容
      type: Boolean,
      default: true
    },
    length: { // 密码最大长度
      type: Number,
      default: 6
    },
    info: { // 输入框下方文字提示
      type: String,
      default: ''
    }
  },
  data(){
    return{
      nativeInputFocus:false,
      showInputCursor:true,
      focusInputIndex:null,
      numberReg :/^\d+$/,
    }

  },
  computed:{
    passwords:function(){
        let value = this.pwdd
        // console.log(this.pwdd,'this.pwdd')
        if (typeof value !== 'string' && typeof value !== 'number') {
          value = '';
        } else {
          value = value + '';
        }
        let resultArr = value.split('');
        let len = this.length;
        let diff = value.length - this.length;
        if (diff > 0) {
          resultArr = value.substr(0, len).split('');
        } else if (diff < 0) {
          diff = Math.abs(diff);
          while (diff > 0) {
            resultArr.push(' ');
            diff--;
          }
        }
        return resultArr;
    }
  },
  mounted(){
    document.addEventListener('keydown', this.keydownEvent, false);
  },
  destroyed(){
    document.removeEventListener('keydown', this.keydownEvent, false);
  },
  methods:{
    trim(str){
      if (typeof str !== 'string' || str.length === 0) {
        return str;
      }
      str += '';
      // 清除字符串两端空格，包含换行符、制表符
      return str.replace(/(^[\s\n\t]+|[\s\n\t]+$)/g, '');

    },
    calcFocusInputIndex(){
      let pwdVal = this.passwords;
      // console.log(pwdVal,'pwdVal')
      let index = -1;
      let realPwdVal = this.trim(pwdVal.join(''));
      // console.log('realPwdVal', realPwdVal, realPwdVal.length, pwdVal);
      for (let i = 0, len = pwdVal.length; i < len; i++) {
        if (pwdVal[i] === ' ' && realPwdVal.length !== this.length) {
          index = i;
          break;
        }
      }
      // console.log('index', index);
      return index;

    },
    focus(){
      let index = this.calcFocusInputIndex();
      // console.log(index,'index')
      if (index > -1) {
        this.$refs.realInput.focus();
        this.nativeInputFocus = true;
        this.showInputCursor = true;
        this.focusInputIndex = index;
      } else {
        this.$refs.realInput.focus();
        this.nativeInputFocus = true;
      }

    },
    blur(){
      this.showInputCursor = false;
      this.focusInputIndex = null;
      this.$refs.realInput.blur();
      this.$refs.realInput.value = '';
      this.nativeInputFocus = false;
    },
   
     onInput(evt) {
      let inputValue = evt.target.value;
      if (inputValue && !this.numberReg.test(inputValue)) { // 如果输入的不是数字则清空输入框
        evt.target.value = '';
        return;
      }
      // console.log('输入的字符为：', inputValue);
      let password = this.passwords.join('');
      password = this.trim(password);
      password += inputValue;
      evt.target.value = '';
      // console.log(password,'password')
      this.$emit('update:pwdd', password);
      if (password.length == this.length) {
        this.$emit('complete', password);
      }
      // 隐藏输入框焦点
      this.$nextTick(function () {
        let inputIndex = this.calcFocusInputIndex();
        if (inputIndex == -1) {
          this.blur();
        } else {
          this.focusInputIndex = inputIndex;
        }
      });
      // console.log('更新pwdd', password);
    },
    keydownEvent(evt){
       let keyCode = evt.keyCode;
      // console.log('keyCode', keyCode);
      if (!this.nativeInputFocus) {
        // console.log('原生输入框未获得焦点');
        return;
      }
      if (keyCode == 8) { // 删除键
        let password = this.passwords.join('');
        password = this.trim(password);
        if (password.length == 0) {
          return;
        }
        password = password.substr(0, password.length - 1);
        // console.log('new password', password);
        this.$emit('update:pwdd', password);
        // 隐藏输入框焦点
        this.$nextTick(function () {
          let inputIndex = this.calcFocusInputIndex();
          if (inputIndex == -1) {
            this.blur();
          } else {
            this.focusInputIndex = inputIndex;
            this.focus();
          }
        });
      }

    }
  }
}



</script>


<style lang="scss">
@import "password-input";
</style>